//const appURL = "http://localhost:3001/api/";
const appURL = "https://app.inspectiv.saft.industries/api/";
const authToken = localStorage.getItem("Auth-Token");

async function postData(url = "", data = {}) {
	// Default options are marked with *
	const response = await fetch(url, {
	  method: "POST", // *GET, POST, PUT, DELETE, etc.
	  mode: "cors", // no-cors, *cors, same-origin
	  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: "include", // include, *same-origin, omit
	  headers: {
		"Content-Type": "application/json",
		// 'Content-Type': 'application/x-www-form-urlencoded',
	  },
	  redirect: "follow", // manual, *follow, error
	  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	  body: JSON.stringify(data), // body data type must match "Content-Type" header
	});

	return response.json(); // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {
	// Default options are marked with *
	const response = await fetch(url, {
	  method: "GET", // *GET, POST, PUT, DELETE, etc.
	  mode: "cors", // no-cors, *cors, same-origin
	  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: "include", // include, *same-origin, omit
	  headers: {
		"Content-Type": "application/json",
		"Access-Control-Request-Headers": "Auth-Token",
		"Auth-Token": authToken
	  },
	  redirect: "follow", // manual, *follow, error
	  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	});

	console.log(response.status);
	let answer = await response.json();
	console.log(answer);

	return answer; // parses JSON response into native JavaScript objects
}

/*
postData("https://example.com/answer", { answer: 42 }).then((data) => {
	console.log(data); // JSON data parsed by `data.json()` call
});
*/

async function checkUser() {

	try {

		let userInfo = await getData(appURL + "check-user", {});

		if (userInfo.status == "err") {
			location = "login.html";
			return;
		}

		const spanFirstName = document.getElementById("spanFirstName");
		if (spanFirstName) spanFirstName.innerText = userInfo.data.first_name;

		const linkProfileEmail = document.getElementById("linkProfileEmail");
		if (linkProfileEmail) linkProfileEmail.innerHTML += userInfo.data.email_username;

		// Temp redirection
		location = "registros.html";

	} catch (err) {
		alert("Ocurrió un error. Favor de intentar más tarde.");
	}

}

async function logout() {
	window.event.preventDefault();
	
	try {

		await getData(appURL + "logout", {});
		localStorage.removeItem("Auth-Token");
		location = "login.html";

	} catch (err) {
		alert("Ocurrió un error. Favor de intentar más tarde.");
	}

}

const linkLogout = document.getElementById("linkLogout");
if (linkLogout) linkLogout.onclick = logout;

checkUser();